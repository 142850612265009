<template>
    <el-drawer
        title="签到信息"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        :append-to-body="true"
        direction="rtl"
        custom-class="demo-drawer"
        :ref="'editDrawer' + queryParams.courseId"
        size="80%" >
        <div class="demo-drawer__content">
            <el-row class="query-container">
                <el-col :span="24">
                    <el-form :inline="true">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width: 140px"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
            <!-- 列表 -->
            <el-row class="table-container">
                <el-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        highlight-current-row
                        style="width: 100%;"
                        ref="queryTable"
                        height="calc(100vh - 250px)"
                        border
                        default-expand-all>
                        <el-table-column prop="trueName" label="姓名" width="180"></el-table-column>
                        <el-table-column prop="phone" label="手机号" width="180" align="center"></el-table-column>
                        <el-table-column label="上课签到" align="center">
                            <el-table-column prop="address" label="签到时间" align="center">
                                <template slot-scope="{row}">
                                    <span v-if="row.start">{{ row.start.signinTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="人像" align="center">
                                <template slot-scope="{row}">
                                    <el-avatar v-if="row.start" shape="square" :size="150" :src="row.start.photoImgUrl"></el-avatar>
                                    <el-button v-else type="text" size="mini"  @click="toSign(row, 1)">补签</el-button>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="下课签到" align="center">
                            <el-table-column prop="address" label="签到时间" align="center">
                                <template slot-scope="{row}">
                                    <span v-if="row.end">{{ row.end.signinTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="人像" align="center">
                                <template slot-scope="{row}">
                                    <el-avatar v-if="row.end" shape="square" :size="150" :src="row.end.photoImgUrl"></el-avatar>
                                    <el-button v-else type="text" size="mini"  @click="toSign(row, 3)">补签</el-button>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <!-- 分页组件 -->
                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                @pagination="initTable"/>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">关闭</el-button>
        </div>
        <SigninDialog ref="signinDialog" @callback="saveSign"></SigninDialog>
    </el-drawer>
</template>

<script>
import SigninDialog from './SigninDialog'
import Pagination from '@/components/Pagination';
export default {
    components: {
        SigninDialog,
        Pagination
    },
    data () {
        return {
            openDrawer: false,
            classId: null,
            activeName: 'tab02',
            info: {
                orderNum: 0,
            },
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            queryParams: {
                pageNumber: 0,
                pageSize: 20,
                totalRecord: 0,
                keyword: '',
                classId: '',
                courseId: '',
            },
            tableData: [
                {
                    start: {
                        photoImgUrl: ''
                    },
                    end: {
                        photoImgUrl: ''
                    }
                }
            ],
            tableLoading: false,
            signInfo: {}
        };
    },
    methods: {
        open: function (classId, courseId) {
            this.queryParams.classId = classId
            this.queryParams.courseId = courseId
            this.openDrawer = true;
            this.tableData = [
                {
                    start: {
                        photoImgUrl: ''
                    },
                    end: {
                        photoImgUrl: ''
                    }
                }
            ]
            this.queryParams.totalRecord = 0
            this.refreshTable();
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('/course/class/offline/course/querySignin', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                    this.$forceUpdate()
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        toSign: function (row, signinType) {
            this.signInfo.userId = row.userId
            this.signInfo.signinType = signinType
            this.$refs.signinDialog.initData();
        },
        saveSign: function (photoImgBase64) {
            let url = "/user/offline/signin/save";
            this.signInfo.courseId = this.queryParams.courseId;
            this.signInfo.classId = this.queryParams.classId;
            this.signInfo.signinMethod = 1;
            this.signInfo.photoImgBase64 = photoImgBase64;

            this.signInfo.createByname = this.appCookies.getCookieUNAME() ;
            this.signInfo.modifyBy = this.appCookies.getCookieUID();
            this.signInfo.modifyByname = this.appCookies.getCookieUNAME() ;
            // 保存数据
            this.apis.postJson(url, this.signInfo)
                .then((res) => {
                    if (res.code === 1) {
                        this.refreshTable()
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped>

</style>
