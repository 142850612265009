<template>
    <el-drawer
        title="线下课程管理"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="100%" >
        <div class="demo-drawer__content">
            <el-row :gutter="8" class="form-row-container">
                <el-col :span="24">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="课程安排设置" name="tab01">
                            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="讲师" prop="teacherName">
                                            <el-input v-model="info.teacherName"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="上课地点" prop="courseAddress">
                                            <el-input v-model="info.courseAddress"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="经纬度">
                                            <div style="display: flex; flex-direction: row">
                                                <el-input v-model="info.lng" placeholder="请输入经度"></el-input>
                                                <span style="margin: 0 5px">-</span>
                                                <el-input v-model="info.lat" placeholder="请输入纬度"></el-input>
                                                <el-button size="mini" icon="el-icon-location"
                                                           circle style="margin-left: 4px"
                                                           @click="showMarkMap(info)"></el-button>
                                            </div>
                                            <div><i class="el-icon-question" style="color: #999999"></i>例如：北京（<span style="margin-right: 20px">经度：116.4</span><span>纬度：39.9</span>）</div>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="开始前是否打卡" prop="startSign" >
                                            <el-radio-group v-model="info.startSign">
                                                <el-radio :label="0">否</el-radio>
                                                <el-radio :label="1">是</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="开始前多少分钟" prop="startMinute" >
                                            <el-input v-model="info.startMinute">
                                                <template slot="append">分钟</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="结束后是否打卡" prop="endSign" >
                                            <el-radio-group v-model="info.endSign">
                                                <el-radio :label="0">否</el-radio>
                                                <el-radio :label="1">是</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="结束后多少分钟" prop="endMinute" >
                                            <el-input v-model="info.endMinute">
                                                <template slot="append">分钟</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="32">
                                    <el-col :span="24" style="text-align: center">
                                        <el-button size="medium" type="primary" @click="saveBase('editForm')">保  存</el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="课程安排" name="tab02">
                            <el-row class="query-container">
                                <el-col :span="24">
                                    <el-form :inline="true">
                                        <el-row>
                                            <el-col :span="24">
                                                <el-form-item label="">
                                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" clearable style="width: 140px"></el-input>
                                                </el-form-item>
                                                <el-form-item>
                                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                                </el-form-item>
                                                <el-button-group style="float: right">
                                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toAdd">新建课程</el-button>
                                                    <el-button class="filter-item" type="success" round icon="el-icon-upload2" @click="toImport">导入课程安排</el-button>
                                                </el-button-group>
                                                <el-link href="/model/importOfflineCource.xlsx" icon="el-icon-download" :underline="false" target="_blank" style="float: right; margin-right: 10px">下载模板</el-link>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <!-- 列表 -->
                            <el-row class="table-container">
                                <el-col>
                                    <el-table
                                        v-loading="tableLoading"
                                        :data="tableData"
                                        highlight-current-row
                                        style="width: 100%;"
                                        ref="queryTable"
                                        height="calc(100vh - 290px)"
                                        row-key="rowKey"
                                        border
                                        default-expand-all>
                                        <el-table-column prop="courseDate" label="日期" width="180"></el-table-column>
                                        <el-table-column prop="courseName" label="课程名称" width="180"></el-table-column>
                                        <el-table-column prop="address" label="上课时间" align="center">
                                            <template slot-scope="{row}">
                                                {{ row.startTime}}-{{ row.endTime}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="courseAddress" label="上课地点"></el-table-column>
                                        <el-table-column prop="teacherName" label="讲师" align="center"></el-table-column>
                                        <el-table-column prop="address" label="签到人数" align="center">
                                            <template slot-scope="{row}">
                                                {{ row.signCountStart}} -  {{ row.signCountEnd}}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="操作" align="center" width="160" fixed="right" class-name="small-padding fixed-width">
                                            <template slot-scope="{row}">
                                                <div class="oper-col">
                                                    <el-button v-if="row.deleteStatus === 0" type="text" size="mini"  @click="showSignin(row.courseId)">签到信息</el-button>
                                                    <el-button v-if="row.deleteStatus === 0" type="text" size="mini"  @click="toEdit(row.courseId)">编辑</el-button>
                                                    <el-button v-if="row.deleteStatus === 0" type="text" size="mini"  @click="toDelete(row.courseId)">删除</el-button>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">关闭</el-button>
        </div>
        <MapMarkDialog ref="baiduMap" @callback="getgeocode"></MapMarkDialog>
        <OfflineCourceEditDialog ref="offlineCourceEditDialog" @callback="refreshTable"></OfflineCourceEditDialog>
        <CourseOfflineSigninListDrawer ref="courseOfflineSigninListDrawer"></CourseOfflineSigninListDrawer>
        <!-- 【导入】对话框 -->
        <el-dialog
            title="数据导入"
            :visible.sync="importModal"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            width="700px">
            <el-row :gutter="16">
                <el-col :span="23">
                    <el-form ref="importForm" :model="info" label-position="right" label-suffix=":" :rules="ruleValidate" label-width="150px">
                        <el-form-item label="上传文件">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                :action="getUploadUrl()"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                            <span v-if="file !== null">{{file.name}}</span>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importModal = false">取消</el-button>
                <el-button type="primary" @click="importData('importForm')" :loading = uploading>
                    {{ uploading ? '上传中' : '确认上传'}}</el-button>
            </span>
        </el-dialog>
    </el-drawer>
</template>

<script>
import MapMarkDialog from "@c/map/MapMarkDialog";
import OfflineCourceEditDialog from './OfflineCourceEditDialog'
import CourseOfflineSigninListDrawer from './CourseOfflineSigninListDrawer'

export default {
    components: {
        MapMarkDialog,
        OfflineCourceEditDialog,
        CourseOfflineSigninListDrawer
    },
    data () {
        return {
            openDrawer: false,
            classId: null,
            activeName: 'tab02',
            info: {
                teacherName: '',
                orderNum: 0,
            },
            mainLoading: false,
            mainLoadingText: '正在导出数据，请稍等',
            queryParams: {
                pageNumber: 0,
                pageSize: 10000,
                totalRecord: 0,
                keyword: '',
                classId: '',
            },
            tableData: [],
            tableLoading: false,
            //表单验证规则
            ruleValidate: {
                // 视频基本信息
                teacherName: [
                    { required: true, message: '请输入讲师', trigger: 'blur' }
                ],
                courseAddress: [
                    { required: true, message: '请输入上课地点', trigger: 'blur' }
                ],
                startSign: [
                    { required: true, message: '请选择开始前是否打卡', trigger: 'change' }
                ],
                startMinute: [
                    { required: true, message: '请输入开始前多少分钟', trigger: 'blur' }
                ],
                endSign: [
                    { required: true, message: '请选择结束后是否打卡', trigger: 'change' }
                ],
                endMinute: [
                    { required: true, message: '请输入结束后多少分钟', trigger: 'blur' }
                ],
            },
            file: null,
            importModal: false,
            importInfo: {},
            fileList: [],
            uploading: false,
        };
    },
    methods: {
        open: function (classId) {
            this.classId = classId;
            this.queryParams.classId = classId
            this.openDrawer = true;
            this.getByClassId()
            this.initTable();
        },
        getByClassId: function () {
            this.apis.get('course/class/offline/config/getByClassId?classId=' + this.classId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        saveBase: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                console.log(this.$refs)
                if (valid) {
                    let url = "course/class/offline/config/save";
                    this.info.classId = this.classId
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.getByClassId();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        showMarkMap: function () {
            this.$refs.baiduMap.initMap(this.info.lat, this.info.lng, this.info.courseAddress)
        },
        getgeocode(lng, lat) {
            console.log("经度：" + lng);
            console.log("纬度：" + lat);
            this.info.lng = lng
            this.info.lat = lat
            this.$forceUpdate()
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        //获取列表
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('/course/class/offline/course/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        toEdit: function (courseId) {
            this.$refs.offlineCourceEditDialog.open(this.classId, courseId)
        },
        toAdd: function () {
            this.$refs.offlineCourceEditDialog.open(this.classId, '')
        },
        showSignin: function (courseId) {
            this.$refs.courseOfflineSigninListDrawer.open(this.classId, courseId)
        },
        toDelete: function (courseId) {
            let title = '确定要删除此条数据？';
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    courseId: courseId,
                    deleteStatus: 1,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('/course/class/offline/course/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        //导入
        toImport: function () {
            this.file = null;
            this.importModal = true;
        },
        //上传文件
        beforeUpload(file) {
            //文件类型
            const isType = file.type === 'application/vnd.ms-excel'
            const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            const fileType = isType || isTypeComputer
            if (!fileType) {
                this.$message.error('上传文件只能是xls/xlsx格式！')
            }
            this.file = file;
            this.info.file = file;
            return fileType;
        },
        getUploadUrl: function () {
            return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
        },
        fileChange(file, fileList) {
            console.log(file)
            this.file = file.raw
        },
        //确认上传
        importData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.uploading = true;
                    let formData = new FormData();
                    formData.append('file', this.file);
                    let url = "/course/class/offline/course/import";
                    this.info.classId = this.classId
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;

                    formData.append("info", new Blob([JSON.stringify(this.info)], { type: "application/json" }));
                    // 保存数据
                    this.apis.postFile(url, formData)
                        .then((res) => {
                            if (res.code === 1) {
                                this.importModal = false;
                                this.initTable();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                            this.uploading = false;
                        }).catch(err => {
                        this.uploading = false;
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
