<template>
    <el-dialog
        title="手工签到"
        :visible.sync="faceRegDialog"
        :destroy-on-close="true"
        :append-to-body="true"
        width="60%"
        :close-on-click-modal="false">
        <div class="face-box" v-loading="mainLoading" :element-loading-text="mainLoadingText">
            <FaceBox ref="faceBox" @callback="getBase64Img"></FaceBox>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="faceRegDialog = false">关 闭</el-button>
            <el-button @click="toUpload">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import FaceBox from "../../../components/Face/FaceBox";
export default {
    components: {
        FaceBox,
    },
    data() {
        return {
            faceRegDialog: false,
            mainLoading: false,
            mainLoadingText: '正在认证上传，请稍等',
            info: {
                userId: '',
                faceImg: ''
            }
        }
    },
    methods: {
        initData: function (userId) {
            this.faceRegDialog = true;
            this.$nextTick(() => {
                this.$refs.faceBox.init()
            })
        },
        getBase64Img: function (base64) {
            this.info.faceImg = base64;
        },
        toUpload: function () {
            this.$emit('callback', this.info.faceImg)
            this.faceRegDialog = false;
        }
    }
}
</script>

<style scoped lang="less">
.face-box {
    height: 100%;
    margin: 0 auto;
    padding: 20px 40px;
}
</style>
