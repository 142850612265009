<template>
    <el-dialog
        title="课程信息"
        :visible.sync="examPaperDialog"
        :destroy-on-close="false"
        :append-to-body="true"
        width="60%"
        style="height: 90vh"
        :close-on-click-modal="false">
        <el-row :gutter="16" class="table-container">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="100px" label-suffix=":">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="课程名称" prop="courseName">
                            <el-input v-model="info.courseName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上课日期" prop="courseDate">
                            <el-date-picker style="width: 150px"
                                            v-model="info.courseDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="开始日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上课时间" prop="startTime">
                            <el-time-select style="width: 120px; margin-left: 10px"
                                            placeholder="开始时间"
                                            v-model="info.startTime"
                                            :picker-options="{
                                                  start: '07:00',
                                                  step: '00:05',
                                                  end: '22:00'
                                                }">
                            </el-time-select>-
                            <el-time-select style="width: 120px; margin-left: 10px"
                                            placeholder="结束时间"
                                            v-model="info.endTime"
                                            :picker-options="{
                                                  start: '07:00',
                                                  step: '00:05',
                                                  end: '22:00'
                                                }">
                            </el-time-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上课地点" prop="courseAddress">
                            <el-input v-model="info.courseAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经纬度">
                            <div style="display: flex; flex-direction: row">
                                <el-input v-model="info.lng" placeholder="请输入经度"></el-input>
                                <span style="margin: 0 5px">-</span>
                                <el-input v-model="info.lat" placeholder="请输入纬度"></el-input>
                                <el-button size="mini" icon="el-icon-location"
                                           circle style="margin-left: 4px"
                                           @click="showMarkMap(info)"></el-button>
                            </div>
                            <div><i class="el-icon-question" style="color: #999999"></i>例如：北京（<span style="margin-right: 20px">经度：116.4</span><span>纬度：39.9</span>）</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="讲师" prop="teacherName">
                            <el-input v-model="info.teacherName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="课程内容" prop="courseContent" >
                            <el-input type="textarea" :rows="6" v-model="info.courseContent"
                                      placeholder="请输入课程内容" maxlength="1500"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注说明" prop="courseRemark" >
                            <el-input type="textarea" :rows="6" v-model="info.courseRemark"
                                      placeholder="请输入备注说明" maxlength="300"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="examPaperDialog = false">关 闭</el-button>
            <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
        </span>
        <MapMarkDialog ref="baiduMap2" @callback="getgeocode"></MapMarkDialog>
    </el-dialog>
</template>

<script>
import MapMarkDialog from "@c/map/MapMarkDialog";
export default {
    components: {
        MapMarkDialog
    },
    data() {
        return {
            examPaperDialog: false,
            info: {
                classId: '',
                courseName: '',
                courseDate: '',
                startTime: '',
                endTime: '',
                courseAddress: '',
                lng: '',
                lat: '',
                teacherName: '',
                courseContent: '',
                courseRemark: '',
            },
            ruleValidate: {
                mstName: [
                    { required: true, message: '请输入试卷标题', trigger: 'blur' }
                ],
            },
            editDialog: false,
        }
    },
    mounted() {
    },
    methods: {
        open: function (classId, courseId) {
            this.info = {
                classId: '',
                courseName: '',
                courseDate: '',
                startTime: '',
                endTime: '',
                courseAddress: '',
                lng: '',
                lat: '',
                teacherName: '',
                courseContent: '',
                courseRemark: '',
            }
            if (courseId) {
                this.getById(courseId);
            } else {
                this.info.classId = classId;
            }
            this.examPaperDialog = true;
        },
        showMarkMap: function () {
            this.$refs.baiduMap2.initMap(this.info.lat, this.info.lng, this.info.courseAddress)
        },
        getgeocode(lng, lat) {
            console.log("经度：" + lng);
            console.log("纬度：" + lat);
            this.info.lng = lng
            this.info.lat = lat
            this.$forceUpdate()
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "/course/class/offline/course/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$emit('callback')
                                this.examPaperDialog = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        getById: function (courseId) {
            this.apis.get('/course/class/offline/course/getById?courseId=' + courseId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
